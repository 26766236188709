import { Col, Container, Row, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
// import { height, width } from "@mui/system";
import axios from "axios";
// import styled from "@emotion/styled";
// import { colors } from "@mui/material";
import { Link } from "react-router-dom";
import SidebarProfile from "../SidebarProfile";
import '../../styles/profile.css'

import { BASE_URL, tableCustomStyles } from "../../App";
import ScrollTopButton from "../ScrollTop";
import ReactPaginate from "react-paginate";
// import '../styles/table.css'

function ProfileVideos({style}) {


  const [videos , SetVideos] = useState([]);
  const [isLoading , setIsLoading] = useState(true)

  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
    },
});

const getQuizData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/api/homework/videos' , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
     
        SetVideos(response.data.data)
        setIsLoading(false)
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
        setIsLoading(false)

    }
}

useEffect (() =>{
  getQuizData();
}, [])

function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
      const formatter = new Intl.DateTimeFormat('ar-EG', options);
      const formattedDate = formatter.format(date);
      return formattedDate;
    } catch (e) {
      // console.error('Error formatting date', e);
      return '';
    }
  }



const [pageNumber, setPageNumber] = useState(0);
const itemsPerPage = 100;
const pagesVisited = pageNumber * itemsPerPage;

const pageCount = Math.ceil(videos.length / itemsPerPage);
const changePage = (event, newPage) => {
  setPageNumber(newPage);
};
  


  const displayQuizzes = videos
  .filter(video => video !== null)
  .slice(pagesVisited, pagesVisited + itemsPerPage)
  .map((video, index) => (
    <tr key={video.id}>
      <td>{index + 1}</td>
      {/* <td>{video.id}</td> */}
      <td>{video.title}</td>
      <td>{video.video_dauration}</td>
      <td>{video.noviews}</td>
      <td>{formatDate(video.created_at)}</td>
      <td>{formatDate(video.updated_at)}</td>
      <td>
        <Link to={`/videohomework/${video.id}`} className="go-modelanswer">
          مشاهدة الفيديو 
        </Link>
      </td>
    </tr>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  

  return (
    <>
     {
          isLoading
          ?
          <div className={`main-loading ${style ? 'dark-mode' : ''}`}>
          <div className="loading-course"> 
         <h2>  .... جاري تحميل بيانات الفديوهات</h2>
         </div>; 
         </div>
         :
         <div className={`profile d-flex ${style ? 'dark-mode' : ''}`}>
               <SidebarProfile />
                  <div className={`content-profile  ${style ? 'dark-mode' : ''}`}>
                    <Row className="mb-4">
                      <Col lg={12}>
                        <div className="title-main">
                          <h2> فيديوهات  <span>  الواجب </span> </h2>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col lg={12}>
                {
                  videos.length > 0
                  ?
               <div className="main-table">
                     <Table  bordered  hover responsive >
              <thead>
              <tr>
              <th>#</th>
              {/* <th>  رقم الفيديو</th> */}
              <th>  اسم الفيديو </th>
              <th>  مدة الفيديو </th>
              <th>  عدد المشاهدات </th>    
              <th> تاريخ انشاء الفيديو </th>
              <th>   اخر تحديث للفيديو  </th>
              <th>  الفيديو </th>
              </tr>
              </thead>
              <tbody >



              {displayQuizzes}

              </tbody>
              </Table>
              <ReactPaginate
              previousLabel={'السابق'}
              nextLabel={'التالي'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'pagination'}
              previousLinkClassName={'page-link'}
              nextLinkClassName={'page-link'}
              disabledClassName={'disabled'}
              activeClassName={'active'}
              />
              </div>
                :
                <div className="non-courses mb-5  mt-5 ">
                <h2>  مفيش فيديوهات لحد دلوقتي  <span className="d-block mt-1"> ستظهر قريباً  </span></h2>
              </div>

                }
                      </Col>
                    </Row>
                    
                    
                  </div>
              </div>
     }

         
        < ScrollTopButton />

    </>
  );
}

export default ProfileVideos;








