import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import SidebarProfile from "./SidebarProfile";
import ReactPaginate from "react-paginate";

const BASE_URL = "https://admin.moatyinchemistry.com"; 

function ChargeWallet({ style, auth }) {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);
  const [codeTable, setCodeTable] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fawryAmount, setFawryAmount] = useState(""); 


  const token = localStorage.getItem("token");
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": "any",
      Authorization: `Bearer ${token}`,
    },
  });

  const getcodes = async () => {
    try {
      const response = await axiosInstance.get("/api/my-codes-charge-page");
      setCodeTable(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getcodes();
  }, []);

  const handleCharge = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post("/api/codes/charge", { code });
      setCode("");
      swal({
        title: "تم الشحن بنجاح!",

        icon: "success",
        button: "حسناّ!",  
      });

      const chargedCode = response.data.data;
      setWalletBalance((prevBalance) => prevBalance + chargedCode.value);
      setCodeTable((prevTable) => [...prevTable, chargedCode]);

    } catch (e) {
      if (e.response.status === 401) {
        swal({
          title: "تم شحن هذا الكود من قبل!",
          icon: "warning",
          button: "حسناّ!",
        });
        setErrors(e.response.data.errors);
      } else if (e.response.status === 404) {
        swal({
          title: "هذا الكود غير صحيح!",
          icon: "warning",
          button: "حسناّ!",
        });
        setErrors(e.response.data.errors);
      }
     else if (e.response.status === 422) {
        swal({
          title: "  دخل  كود الشحن مينفعش يبقي فاضي ",
          icon: "warning",
          button: "حسناّ!",
        });
        setErrors(e.response.data.errors);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleFawryCharge = async (event) => {
    event.preventDefault();
    const fawryToken = token;

    if (fawryToken && fawryAmount) {
      swal({
        title: "هل أنت متأكد من  شحن المحفظه عن طريق فوري",
        text: " ملحوظة هاااامة: بعد الدفع استني من نص ساعه ل ساعه وهتلاقي الرصيد في محفظتك ",
        icon: "warning",
        buttons: ["إلغاء", "موافق"],
        dangerMode: true,
        customClass: {
          overlay: 'swal-overlay-custom',
        },
      }).then((willProceed) => {
        if (willProceed) {
          const url = `https://moatyinchemistry.com/FawryPay/chargeWallet.html?id=${fawryAmount}&token=${fawryToken}`;
          window.location.href = url;
        }
      });
    } else {
      swal({
        title: "دخل مبلغ الشحن مينفعش يبقي فاضي",
        icon: "warning",
        button: "حسناّ!",
      });
    }
  };

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZone: "UTC",
        numberingSystem: "arab",
      };
      const formatter = new Intl.DateTimeFormat("ar-EG", options);
      return formatter.format(date);
    } catch (e) {
      return "";
    }
  }

  

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const pageCount = Math.ceil(codeTable.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayCodes = codeTable
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((codes, index) => (
      <tr key={codes.id}>
        <td>{index + 1}</td>
        <td>{codes.id}</td>
        <td>{codes.code}</td>
        <td>{codes.value}</td>
      </tr>
    ));

  return (
    <>
      {isLoading ? (
        <div className={`main-loading ${style ? "dark-mode" : ""}`}>
          <div className="loading-course">
            <h2>.... جاري تحميل بيانات المحفظة</h2>
          </div>
        </div>
      ) : (
        <div className={`profile d-flex ${style ? "dark-mode" : ""}`}>
          <SidebarProfile />
          <div className={`content-profile ${style ? "dark-mode" : ""}`}>
            <Row className="mb-4">
              <Col lg={12}>
                <div className="title-main">
                  <h2>
                    بيانات <span> المحفظه </span>
                  </h2>
                </div>
              </Col>
            </Row>
            <Row  className="mb-4 flex-lg-row">
            <Col lg={7}>
                {codeTable.length > 0 ? (
                  <div className="main-table">
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>التسلسل</th>
                          <th>الكود</th>
                          <th>السعر</th>
                        </tr>
                      </thead>
                      <tbody>{displayCodes}</tbody>
                    </Table>
                    <ReactPaginate
                      previousLabel={"السابق"}
                      nextLabel={"التالي"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={"pagination"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                    />
                  </div>
                ) : (
                  <div className="non-courses mb-5 mt-5">
                    <h2>
                      مفيش اكود شحنتها لحد دلوقتي <span className="d-block mt-1"> اشحن علشان تتفرج علي حصصنا </span>
                    </h2>
                  </div>
                )}
              </Col>
              <Col lg={5}>
                <div className="wallet mb-lg-0 mb-4">
                  <div className="title">
                    <h3>
                      رصيدك في <span>المحفظة</span>
                    </h3>
                  </div>
                  <img src="/images/wallet.png" alt="" />
                  <div className="price">
                    <span>رصيدك بالمحفظة </span>
                    <span> جنيه {walletBalance + auth.balance} </span>
                  </div>
                  <Form className="align-items-center mt-4" onSubmit={handleCharge}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        placeholder="ادخل كود الشحن"
                        name="code"
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </Form.Group>
                    <Button variant="outline-danger" type="submit" className="ms-0 w-100">
                      اشحن
                    </Button>{" "}
                  </Form>
                  <hr />
                  <Form
                    className="align-items-center mt-4"
                    onSubmit={handleFawryCharge} 
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        placeholder="ادخل مبلغ الشحن"
                        name="fawryAmount" 
                        value={fawryAmount} 
                        onChange={(e) => setFawryAmount(e.target.value)}
                      />
                    </Form.Group>
                    <Button
                      variant="outline-primary"
                      type="submit"
                      className="ms-0 w-100"
                    >
                      الشحن عن طريق فوري
                    </Button>{" "}
                  </Form>
                </div>
              </Col>
           
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export default ChargeWallet;
