import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";
import { BASE_URL } from "../App";
import swal from 'sweetalert';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";






const initialState = {
    email: '',
    government: '',
    password: '',
    confirmPassword: '',
}


function ResetPasswordAndMail({style , setLoggedIn}) {
    const [formData  , setFormData] = useState(initialState)
    const [errors, setErrors] = useState("");
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const {name , value} =  event.target;
        setFormData((prevData) => ({...prevData , [name] : value}));
    }

    const [loading, setLoading] = useState(false);

        const handleResetPasswordAndMail =  async (event) => {
            event.preventDefault();
            setLoading(true);
            try{
                const token = localStorage.getItem('token');
                    const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        "ngrok-skip-browser-warning": "any",
                        'Authorization': `Bearer ${token}`,
                    }
    };
                const formDataObj = new FormData();
                Object.keys(formData).forEach((key) => {
                    formDataObj.append(key , formData[key]);
                });
                await axios.post(`${BASE_URL}/api/reset-password` , formDataObj , config);
                
                setFormData(initialState);
                swal({
                    title: "تم تسجيل حسابك بنجاح!",
                    // text: `ID الخاص بك ${userId}`,
                    icon: "success",
                    button: "حسناّ!",
                }).then(() => {
                    setLoggedIn(true);

                    navigate("/");
                    window.location.reload();
                  });
                  
            }
            catch(e){
                setLoading(false);

                if(e.response.status === 422) {
                    setErrors(e.response.data.errors);
                }
                swal({
                    title: " !راجع بياناتك تاني  ",
                    icon: "warning",
                    button: "حسناّ!",
                  })
            }
                     
         }

         const [showPassword, setShowPassword] = useState(false);



    return (
        <>
     <div className={`register-user ${style ? 'dark-mode' : ''}`}>
      
      <Container>  
          <Row>
              {/* <Col lg={12}> */}
                  <div className="box mt-2">
                    <Col lg={6}>
                    <Form onSubmit={handleResetPasswordAndMail}>
                    <h2 className="mb-5"> كمل <span> بياناتك </span></h2>

           

             


              <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
  <Form.Label> البريد الألكتروني للطالب</Form.Label>
  <Form.Control type="email"    placeholder="ادخل البريد الألكتروني" value={formData.email} name="email" onChange={handleInputChange}/>
  <Form.Text className="text-muted">
  {errors.email && (
                          <div  className="error">{errors.email[0]}</div>
                      )}
  </Form.Text>
</Form.Group>

            



              <Form.Group className="mb-3 big-input" controlId="formBasicPassword">
              <Form.Label>برجاء اختيار المحافظة</Form.Label>
              <Form.Select aria-label="Default select example" value={formData.government} name="government" onChange={handleInputChange}>

<option >اختر المحافظة</option>
<option value="القاهرة">القاهرة</option>
<option value="الجيزة">الجيزة</option>
<option value="الإسكندرية">الإسكندرية</option>
<option value="الإسماعيلية">الإسماعيلية</option>
<option value="كفر الشيخ">كفر الشيخ</option>
<option value="أسوان">أسوان</option>
<option value="أسيوط">أسيوط</option>
<option value="الأقصر">الأقصر</option>
<option value="الوادي الجديد">الوادي الجديد</option>
<option value ="شمال سيناء">شمال سيناء</option>
<option value="البحيرة">البحيرة</option>
<option value="بني سويف">بني سويف</option>
<option value="بورسعيد">بورسعيد</option>
<option value="البحر الأحمر">البحر الأحمر</option>
<option value="الدقهلية">الدقهلية</option>
<option value="جنوب سيناء">جنوب سيناء</option>
<option value="دمياط">دمياط</option>
<option value="سوهاج">سوهاج</option>
<option value="السويس">السويس</option>
<option value="الشرقية">الشرقية</option>
<option value="الغربية">الغربية</option>
<option value="الفيوم">الفيوم</option>
<option value="القليوبية">القليوبية</option>
<option value="قنا">قنا</option>
<option value="مطروح">مطروح</option>
<option value="المنوفية">المنوفية</option>
<option value="المنيا">المنيا</option>
</Form.Select>
<Form.Text className="text-muted">
  {errors.government && (
                          <div  className="error">{errors.government[0]}</div>
                      )}
  </Form.Text>
</Form.Group>

 









              
              
              <div className="input-group">
              <Form.Group className="big-input mb-3" controlId="formBasicEmail">
<Form.Label>كلمة المرور</Form.Label>
<div className="password-input">
<Form.Control
type={showPassword ? 'text' : 'password'} // Toggle password visibility
placeholder="ادخل  كلمة المرور"
value={formData.password}
name="password"
onChange={handleInputChange}
/>
<span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
</span>
</div>
<Form.Text className="text-muted">
{errors.password && <div className="error">{errors.password[0]}</div>}
</Form.Text>
</Form.Group>

<Form.Group className="mb-3 big-input" controlId="formBasicPassword">
<Form.Label>تأكيد كلمة المرور</Form.Label>
<Form.Control
type={showPassword ? 'text' : 'password'} // Toggle password visibility
placeholder="تأكيد كلمة المرور"
value={formData.password_confirmation}
name="password_confirmation"
onChange={handleInputChange}
/>
<span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
</span>
<Form.Text className="text-muted">
{errors.password_confirmation && <div className="error">{errors.password_confirmation[0]}</div>}
</Form.Text>
</Form.Group>


              </div>

              <Button
variant="outline-primary"
className="mt-3 mb-3"
type="submit"
disabled={loading} // Disable the button during loading
>
{loading ? (
<>
    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {' '}جاري انشاء الحساب...
</>
) : (
'انشئ الحساب'
)}
</Button>{' '}

                          </Form>
                    </Col>
                    <Col lg={6}>
                    <div className="welcome-sign">
              <div className="content text-center">
                <h2> , اهلا بك في منصة <span className="d-block mt-2">مستر  محمد عبد المعطي</span> </h2>
                <p className="mt-4">  كمل بيانات هنا يلا شد حيلك </p>
                {/* <Link className="mt-4 d-block"  to="/loginuser"> ! لدي حساب بالفعل</Link> */}
              </div>
            </div>
                    </Col>
              

                  </div>
              {/* </Col> */}
          </Row>
      </Container>
  </div>

        </>
    )
}

export default ResetPasswordAndMail;