

function PointStore () {


    return (
        <>
        
        </>
    )
}


export default PointStore