// src/QrCodeComponent.js
import React, { useEffect, useState } from 'react';
import { QRCodeSVG  } from 'qrcode.react'; 
import { QrReader } from 'react-qr-reader'; 
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import useParams

const QrCodeComponent = () => {
    const { invoiceId } = useParams(); // Get the invoiceId from route parameters
    const [qrCode, setQrCode] = useState('');

    const generateQrCode = async () => {
        try {
            console.log('Generating QR Code for Invoice ID:', invoiceId);
            const response = await axios.get(`http://localhost:4000/api/invoice/${invoiceId}/genrate-qrcode`);
            console.log('Response:' + response.data.data.qrCode);
            
            if (response.data.data && response.data.data.qrCode) {
                setQrCode(response.data.data.qrCode);
            } else {
                console.error('QR Code not found in response.');
            }
        } catch (error) {
            console.error('Error generating QR Code:', error);
        }
    };

    const handleQrScan = async (scannedInvoiceId) => {
        console.log('Scanned Invoice ID:', scannedInvoiceId);
        try {
            const response = await axios.patch('http://localhost:4000/api/invoice/update-status-order', { invoiceId: scannedInvoiceId });
            console.log('Response:', response.data);
            if (response.data.status === 'success') {
                alert(`Order status updated to: ${response.data.statusOrder}`);
            } else {
                alert(`Failed to update order status: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error updating order status:', error);
            alert('Failed to update order status');
        }
    };

    useEffect(() => {
        console.log('invoiceId:', invoiceId);
        if (invoiceId) {
            generateQrCode();
        }
    }, [invoiceId]);

    console.log(qrCode);
    

    return (
        <div className='pt-5 ms-5 mt-5'>
            {qrCode && (
                <div>
                    <h3>Generated QR Code:</h3>
                    <QRCodeSVG  value={qrCode} size={250}/>
                </div>
            )}
                  {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIQAAACECAYAAABRRIOnAAAAAklEQVR4AewaftIAAAOlSURBVO3BQa5bRwADweZA979yx4ssuBrkQdKPbbAq/sLMvw4z5TBTDjPlMFMOM+UwUw4z5TBTDjPlMFMOM+UwUw4z5TBTDjPlxZuS8JNUbpJwo3KThKbSknCj0pLwk1TecZgph5lymCkvPkzlk5LwTUloKk+oPKHySUn4pMNMOcyUw0x58WVJeELlHSo3SWgqN0m4SUJTeSIJT6h802GmHGbKYaa8+MskoanMf3eYKYeZcpgpL/4yKi0JTeUmCU+o/E0OM+UwUw4z5cWXqXxTEm5U3qHSktCS0FSeUPmdHGbKYaYcZsqLD0vC/0mlJaGptCQ0lZaEptKS8EQSfmeHmXKYKYeZ8uJNKv8nlZaEpnKj8k0qf5LDTDnMlMNMefGmJDSVmyQ0lZaEG5WWhKbSknCj0pLQVG5UWhKaSktCU/mdHGbKYaYcZsqLL0vCTRKayieptCQ8kYSmcqPSkvBJSbhRecdhphxmymGmvPgylZsk3CThm5LwjiQ0lRuVloSm0pLwkw4z5TBTDjMl/sIbknCj0pLwhEpLwhMqN0l4QuWTknCjcpOEpvKOw0w5zJTDTHnxJpWWhE9KwjuScKPSktBUnkjCEyotCTdJ+KbDTDnMlMNMefFhKjcqLQk3Ki0JTaUl4R0qTyThCZUnktBUWhI+6TBTDjPlMFNevCkJTeUmCTcqLQlPqNwk4YkkNJWmcpOEloSm0lRukvBNh5lymCmHmfLiTSotCTcqLQk3Kj8pCTdJeEKlJeEmCU3lJx1mymGmHGZK/IU3JOEJlZsk3Ki0JDyh0pLQVFoSmkpLwu9E5R2HmXKYKYeZ8uJNKp+k8g6VloQnktBUWhJuVJ5Iwo1KS8I3HWbKYaYcZsqLNyXhJ6k0lRuVloQnkvCOJDSVG5WWhKbSkvBJh5lymCmHmfLiw1Q+KQk3SbhRaSotCU3liSTcqDyRhJskNJVPOsyUw0w5zJQXX5aEJ1SeULlJQlO5ScKNyk0SPkmlJeGbDjPlMFMOM+XFHy4JTyShqbQkNJUnVG6S8EQSmso3HWbKYaYcZsqLP5xKS0JTuUnCO1SeUGlJaCpPJKGpvOMwUw4z5TBTXnyZyk9SaUloKk2lJeEmCU+otCQ0lZaEpnKj8kmHmXKYKYeZ8uLDkvCTktBUbpLQVG6S8ITKE0l4Igk3Ku84zJTDTDnMlPgLM/86zJTDTDnMlMNMOcyUw0w5zJTDTDnMlMNMOcyUw0w5zJTDTDnMlH8AAgeXKUjRoicAAAAASUVORK5CYII="/> */}

            <QrReader
                onScan={(data) => {
                    if (data) {
                        handleQrScan(data);
                    } else {
                        console.log('No data found.');
                    }
                }}
                onError={(err) => console.error('QR scan error:', err)}
                style={{ width: '100%' }}
            />
            
        </div>
    );
};

export default QrCodeComponent;
