

import { Col, ProgressBar, Row } from 'react-bootstrap';
import '../styles/card.css'
import '../styles/points.css'

import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL, Image_URL } from '../App';
import ReactConfetti from 'react-confetti';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';



function Points({style , auth}) {


   


    
         

      const [top , setTop] = useState([])
      const [rewards , setRewards] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const fetchData = async () => {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
            'Authorization': `Bearer ${token}`,
          }
        };
    
        try {
          const response = await axios.get(`${BASE_URL}/api/market-info`, config);
                setTop(response.data.data.top10UsersInPoints)
                setRewards(response.data.data.points_rewards)
          setIsLoading(false); 
        } catch (error) {
          setIsLoading(false); 
        }
      }

    
      
    
      // useEffect(() => {
      //   fetchData();
      //   window.scrollTo(0, 0);
      // }, []);
    
      const maxPoints = Math.max(...top.map((t) => t.points))
      const progressBarWidth = 250000; 

      
 
      useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
        if (auth) {
          swal({
            title: 'نقاطك',
            text: `لديك ${auth.points} نقطة`,
            icon: 'info',
            confirmButtonText: 'موافق'
          });
        } else {
          swal({
            title: 'نقاطك ',
            text: 'سجل الاول علشان يبدأ يتحسبلك بوينتات',
            icon: 'warning',
            confirmButtonText: 'موافق'
          });
        }
      }, [auth]);
      


      if (isLoading) {
        return <div className={`main-loading ${style ? 'dark-mode' : ''}`}>
          <div className="loading-course"> 
        <h2>  <span>.</span><span>.</span><span>.</span><span>.</span> جاري عرض الأوائل</h2>
        </div>
        </div>
      }

      function formatDate(dateString) {
        try {
          const date = new Date(dateString);
          const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
          const formatter = new Intl.DateTimeFormat('ar-EG', options);
          const formattedDate = formatter.format(date);
          return formattedDate;
        } catch (e) {
          // console.error('Error formatting date', e);
          return '';
        }
      }

    return (
        <>
       
                  
                    <div className={`points ${style ? 'dark-mode' : ''}`}>
                    <div className="react-confetti-container">
        <ReactConfetti
            numberOfPieces={120}
            recycle={true}
            width={window.innerWidth}
            height={window.innerHeight}
            gravity={0.04}
        />
        </div> 

                        <Row >
                            <Col lg={12}>
                                <div className='top-10'>
                                <div className="btn-topRated pt-5 mb-5">
                    <button className="button-85" role="button"> اوائل المنصه </button>
                    </div>

                    <div className='top-10-named'>
                        
                    {
                        top.map((t , index) => (
                            <div className='info mt-3' key={t.fname}>
                            <div className='d-flex align-items-center'>
                            {index === 0 && <img src='/images/1st-prize_11224616.png' alt='first' loading='lazy' />}
                {index === 1 && <img src='/images/2nd-place_11166537.png' alt='second' loading='lazy' />}
                {index === 2 && <img src='/images/3rd-place_11166539.png' alt='third' loading='lazy' />}
                {index === 3 && <span className='rank'> 4th: </span>}
                {index === 4 && <span className='rank'> 5th: </span>}
                {index === 5 && <span className='rank'> 6th: </span>}
                {index === 6 && <span className='rank'> 7th: </span>}
                {index === 7 && <span className='rank'> 8th: </span>}
                {index === 8 && <span className='rank'> 9th: </span>}
                {index === 9 && <span className='rank'> 10th: </span>}

                            <h3>{t.fname + ' ' + t.lname}</h3>
                        
                            </div>
                            <div className="progress-bar">
                            <div className="progress" style={{ width: `${(t.points / maxPoints) * progressBarWidth}px` }}>
                  <span className="progress-text">{t.points} P</span>
                </div>
                            </div>
                          
                        </div>
                        ))
                    }
                    
                    </div>
                                </div>
                            </Col>
                            
                        </Row>
                        <Row>
                          <Col lg={12}>
                   
                          <div className='store mt-5'>
                          <div className="btn-topRated pt-5 mb-5">
                    <button className="button-85" role="button">  استور الجوائز </button>
                    </div>
                          </div>


                                  <div className='coursess'>
                                  <Row>
                                  {
                                    rewards && rewards.length > 0 ?
                                  rewards.map((reward , index) => (
                                       
                                        <Col lg={3} key={reward.id}>
                                        <div className="card mb-4">
                                      <div className="image">
                                      <img src={`${Image_URL}/storage/${reward.img}`} alt="" loading="lazy"/>
                                      </div>
                                      <div className="about">
                                            <div className="details">
                                            <h3> {reward.title}</h3>
                                             <p> {reward.description}</p>
                                            </div>
                                        <div className="price">
                                  
                                         
                                            <span className="free"> {reward.points} </span>
                                           
                                        
                                  
                                        
                                        
                                      </div>
                                      <div className="dates mt-4">
                                        <div className="date">
                                          <img src="/images/plus..webp" alt="" loading="lazy"/>
                                          <span className="ms-2">  {formatDate(reward.created_at)}      </span>
                                        </div>
                                        <div className="date mt-2">
                                          <img src="/images/update..webp" alt="" loading="lazy"/>
                                          <span className="ms-2">  {formatDate(reward.updated_at)}      </span>
                                        </div>
                                      
                                      </div>
                                      <div className="btns">
                                       
                                 <Link className='cardbtn-profile' to="#">
                                      استبدل الجائزه بالنقاط
                                 </Link>
                                      </div>
                                   
                                      </div>
                                    
                                    </div>
                                    </Col>
                                  
                         
                              ))
                            :
                             <div className="non-courses mb-5  mt-5 ">
                            <h2>    لا يوجد جوايز متوفرة حتي الأن  <span className="d-block mt-1">سيتم اضافة الجوايز  قريباََ</span> </h2>
                          </div>  
                            }
                                  </Row>
                                  </div>
                            
                
                          </Col>
                        </Row>

                        {/* <div className="non-courses mb-5  mt-5 ">
                            <h2>    لا يوجد جوايز متوفرة حتي الأن  <span className="d-block mt-1">سيتم اضافة الجوايز  قريباََ</span> </h2>
                          </div>  */}
                    </div>
                  
        </>
    )
}


export default Points;